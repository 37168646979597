<template>
  <v-container class="mt-16">
    <overlay-loader overlay :loading="loadingPage"></overlay-loader>
    <v-row v-if="!loadingPage" justify="center" align-content="center">
      <v-col>
        <v-alert text type="success" prominent width="100%" color="success">
          <span v-if="byBuy === 'true'">Успешно!</span>
          <span v-else>Билет уже продан!</span>
        </v-alert>
        <v-container>
          <v-form ref="printCheck">
            <v-row align="baseline">
              <v-text-field
                class="mr-3"
                outlined
                :min="0"
                :max="200"
                type="number"
                v-model="cash"
                label="Нал"
                :rules="paymentRules"
              ></v-text-field>
              <v-btn icon class="mr-3" @click="exchangeFOPs">
                <v-icon>la-exchange-alt</v-icon>
              </v-btn>
              <v-text-field
                type="number"
                outlined
                :rules="paymentRules"
                label="Без-Нал"
                v-model="card"
              ></v-text-field>
              <v-btn
                class="ml-5"
                :disabled="checkPrinted || true"
                color="orange"
                elevation="0"
                :dark="!checkPrinted"
                :loading="loadingCheck"
                @click="printCheck(true)"
              >
                Распечатать чек
              </v-btn>
              <v-btn
                class="mx-5"
                color="orange"
                elevation="0"
                dark
                id="getPdf"
                :loading="loadingPdf"
                @click="getPdf"
              >
                Скачать Pdf
              </v-btn>
              <refund-btn
                :regnum="regnum"
                :surname="surname"
                :pult="pult"
                :isRefundable="isRefundable"
                @refund="printCheck(false)"
              />
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>

    <shift-dialog v-model="shiftError"></shift-dialog>
  </v-container>
</template>

<script>
import RefundBtn from "@/components/UI/RefundBtn";
import ErrorModal from "@/components/UI/ErrorModal";
import ShiftDialog from "@/components/UI/ShiftDialog";
import OverlayLoader from "@/components/UI/OverlayLoader";
import KKT from "@/mixins/KKT";
import ApiCalls from "@/mixins/booking/ApiCalls";

export default {
  name: "ShowReceipt",
  components: { ShiftDialog, RefundBtn, OverlayLoader, ErrorModal },
  mixins: [KKT, ApiCalls],
  props: ["regnum", "surname", "pult", "isRefundable", "byBuy"],
  data() {
    return {
      pdfByBase64: null,
      pdf: null,
      loadingPage: true,
      loadingPdf: false,
      loadingCheck: false,
      shiftError: false,
      checkPrinted: false,
      order: null,
      cash: 0,
      card: 0,
      maxPayment: 0,
      paymentRules: [
        (v) => (v <= this.totalPaymentAmount && v >= 0) || "Unavailable value",
      ],
    };
  },
  async mounted() {
    try {
      this.loadingPage = true;
      await this.getOrder();
      await this.CheckPrintedInfo();
      this.loadingPage = false;
    } catch (e) {
      this.loadingPage = false;
      console.log("Ошибка в mounted");
    }
  },
  watch: {
    cash(v) {
      this.card = this.totalPaymentAmount - (v ? parseFloat(v) : 0);
    },
    card(v) {
      this.cash = this.totalPaymentAmount - (v ? parseFloat(v) : 0);
    },
  },
  methods: {
    async getPdf() {
      this.loadingPdf = true;
      if (!!this.pdfByBase64) this.downloadPdf(this.pdfByBase64);
      else {
        try {
          this.pdfByBase64 = await this.GetPDF(
            this.pult,
            this.regnum,
            this.surname
          );
          this.pdf = this.pdfByBase64;
          this.downloadPdf(this.pdfByBase64);
        } catch (e) {
          console.log(e);
        }
      }
      this.loadingPdf = false;
    },
    downloadPdf(str) {
      try {
        const linkSource = `data:application/pdf;base64,${str}`;
        const downloadLink = document.createElement("a");
        const fileName = `${this.surname}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        console.log("download complete");
      } catch (e) {
        console.log("download Error");
        console.log(e);
        throw e;
      }
    },
    async printCheck(sell) {
      if (!this.$store.getters.shiftOpen || this.$store.getters.shiftExpired) {
        this.shiftError = true;
        return;
      }
      if (this.$refs.printCheck.validate()) {
        this.loadingCheck = true;
        let items = [];
        for (const price of this.order.Pnr.Prices.Price) {
          let total = parseFloat(price.Total);
          let vat = "vat0";
          if (price.Vat) {
            let tax =
              parseFloat(price.Vat.Fare ?? "0") +
              parseFloat(price.Vat.Zz ?? "0");
            let percent = Math.floor(total / tax - 1);
            vat = percent === 5 ? "vat20" : percent === 10 ? "vat10" : "vat0";
          }
          items.push({
            type: "position",
            name:
              (price.DocType === "ticket"
                ? "Авиабилет "
                : "дополнительная услуга ") +
              price.Accode +
              " " +
              price.Ticket,
            price: total,
            quantity: 1,
            amount: total,
            department: 1,
            measurementUnit: "шт",
            paymentMethod: "fullPayment",
            paymentObject: "service",
            tax: {
              type: vat,
            },
            supplierInfo: {
              phones: ["+79175555555"],
              name: "Supplier",
              vatin: "956839506500",
            },
          });
        }
        let uid = Date().toString();
        let sellReq = {
          uuid: uid,
          request: {
            type: sell ? "sell" : "sellReturn",
            taxationType: "usnIncomeOutcome",
            ignoreNonFiscalPrintErrors: true,

            operator: {
              name: this.$store.state.name,
              vatin: this.$store.state.inn,
            },

            items: items,
            payments: [
              {
                type: "cash",
                sum: parseFloat(this.cash),
              },
              {
                type: "electronically",
                sum: parseFloat(this.card),
              },
            ],
          },
        };
        await this.$axios.post(this.$store.state.kktUrl, sellReq);
        await this.$axios.put(
          `/checkprinted?regnum=` + this.regnum + "&surname=" + this.surname
        );
        this.checkPrinted = false;
        this.loadingCheck = false;
      }
    },
    exchangeFOPs() {
      let tmp = this.cash;
      this.cash = this.card;
      this.card = tmp;
    },
  },
  computed: {
    totalPaymentAmount() {
      return (
        this.servicesPaymentSum +
        parseFloat(this.order.Pnr.Prices.VariantTotal.Text)
      ).toFixed(2);
    },
    servicesPaymentSum() {
      let sum = 0;
      this.order.Pnr.Prices.Price.forEach((p) => {
        if (p.DocType === "emd") {
          let ser = this.order.Pnr.Svcs.Svc.find(
            (s) => s.Id === p.SvcId && s.Status !== "HN" && s.Status !== "UN"
          );
          if (ser) sum += parseFloat(p.Total);
        }
      });
      return sum;
    },
  },
};
</script>

<style scoped></style>
