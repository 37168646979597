<template>
  <div class="text-center">
    <v-overlay
      :value="loading"
      z-index="0"
      :opacity="overlay ? 0.5 : 0.0"
      :absolute="absolute"
    >
      <v-row align="center" justify="center">
        <v-progress-circular indeterminate color="orange" size="60">
        </v-progress-circular>
      </v-row>
      <v-row align="center" justify="center">
        <slot></slot>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "OverlayLoader",
  props: { loading: Boolean, absolute: Boolean, overlay: Boolean },
};
</script>

<style scoped></style>
